<script>
export default {
  components: {},
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {},
    className: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<template>
  <div class="col-12 lg:col-6 xl:col-3">
    <div class="card mb-0">
      <div class="flex justify-content-between">
        <div>
          <span class="block text-500 font-medium mb-3">{{ this.label }}</span>
          <span class="dashboard-item-value">{{ this.modelValue }}</span>
        </div>
        <div
          v-if="this.icon"
          class="flex align-items-center justify-content-center bg-blue-100 border-round"
          style="width: 2.5rem; height: 2.5rem"
        >
          <i :class="this.icon"></i>
        </div>
      </div>
    </div>
  </div>
</template>
