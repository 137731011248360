<template>
  <div class="grid">
    <Toast />
    <div class="col-12 lg:col-6 xl:col-3">
      <router-link :to="{ name: 'spectacleList' }" target="_blank">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3"
                >Accéder à la vue publique</span
              >
              <span class="dashboard-item-text text-500"
                >La page que les écoles voient</span
              >
            </div>
            <div
              class="flex align-items-center justify-content-center bg-blue-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-home text-blue-500 text-xl"></i>
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <dashboard-item
      label="Nombre de visiteurs uniques"
      icon="pi pi-user text-blue-500 text-xl"
      v-model="this.count_visitors"
    ></dashboard-item>
  </div>
  <CommonSpectacleReservationList
    title="Liste des réservations en attente de validation"
    v-if="getModuleAccess('reservations')"
    :reservation-filters="this.reservationFilters"
  ></CommonSpectacleReservationList>
</template>

<script>
import SpectacleReservationService from "@services/SpectacleReservationService";
import CommonSpectacleReservationList from "@components/CommonSpectacleReservationList";
import { getModuleAccess } from "../utils/utils";
import DashboardItem from "./DashboardItem.vue";
import VariableService from "@services/VariableService";

export default {
  components: { CommonSpectacleReservationList, DashboardItem },
  data() {
    return {
      products: null,
      lineData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Revenue",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: "#2f4860",
            borderColor: "#2f4860",
            tension: 0.4,
          },
          {
            label: "Sales",
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: "#00bb7e",
            borderColor: "#00bb7e",
            tension: 0.4,
          },
        ],
      },
      items: [
        { label: "Add New", icon: "pi pi-fw pi-plus" },
        { label: "Remove", icon: "pi pi-fw pi-minus" },
      ],
      reservations: [],
      spectacleReservationService: null,
      reservationFilters: {
        accepted: "waiting",
      },
      count_visitors: null,
    };
  },
  variableService: null,

  computed: {
    tempReservationStateTrad() {
      if (this.tempReservationState == "valid") {
        return "valider";
      } else return "refuser";
    },
  },

  created() {
    this.variableService = new VariableService();
    this.spectacleReservationService = new SpectacleReservationService();
  },
  mounted() {
    this.variableService.variable("count_visitors").then((data) => {
      this.count_visitors = data.valueLong;
    });
  },
  methods: {
    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    getModuleAccess,
  },
};
</script>
